import { tryCatch } from '@thalesrc/js-utils';

import http from './http';
import { MODULE_URL } from '../utils/url';

async function list(payload) {
  const [error, result] = await tryCatch(http.post(`${MODULE_URL}/search`, payload));

  if (error) throw error;

  return result.data.data;
}

async function getAll() {
  const [error, result] = await tryCatch(http.get(`${MODULE_URL}/authorized-modules`));

  if (error) throw error;

  return result.data.data;
}

async function getForDropdown() {
  const url = `${MODULE_URL}/dropdown-modules`;

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}

async function detail(id) {
  const url = `${MODULE_URL}/modules/${id}`;

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}

async function add(payload) {
  const [error, result] = await tryCatch(http.post(MODULE_URL, payload));

  if (error) throw error;

  return result.data.data;
}

async function update(payload) {
  const [error, result] = await tryCatch(http.put(MODULE_URL, payload));

  if (error) throw error;

  return result.data.data;
}

export const moduleService = {
  getAll,
  getForDropdown,
  list,
  detail,
  add,
  update
};
