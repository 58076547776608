import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { tryCatch } from '@thalesrc/js-utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LoadingContext } from './Loading';
import { ToasterContext } from './ToasterContext';
import { LOCAL_KEYS } from '../enums/const';
import { useStateStorage } from '../hooks/use-state-storage';
import { authService, loginUser, logoutUser, verifyCodeRequest } from '../services/auth';
import { userService } from '../services/user';
import pageURL from '../utils/pageUrls';

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const { t } = useTranslation();

  const history = useHistory();

  const { openToaster } = useContext(ToasterContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [token, setToken, removeToken] = useStateStorage(LOCAL_KEYS.Token);
  const [user, setUser, removeUser] = useStateStorage(LOCAL_KEYS.User);

  const [requiredOtpResult, setRequiredOtpResult] = useState({
    otpRequired: false,
    transactionGuid: null,
    isLogined: false
  });

  useEffect(() => {
    const userLocal = localStorage.getItem(LOCAL_KEYS.User);

    if (userLocal) {
      setUser(JSON.parse(userLocal));
    }
  }, []);

  const login = useCallback(async (payload) => {
    showLoading();
    const [err, res] = await tryCatch(loginUser(payload));

    if (err) {
      openToaster('top-right', {
        severity: 'error',
        summary: t('message.error'),
        detail: t(err?.response?.data?.errors[0]),
        life: 3000
      });
      hideLoading();
      return;
    }

    if (res) {
      const { transactionGuid, accessToken, user: responseUser } = res;

      if (transactionGuid) {
        setRequiredOtpResult({
          ...requiredOtpResult,
          otpRequired: true,
          transactionGuid
        });
      }

      if (accessToken) {
        setToken(accessToken.token);
        setUser(responseUser);
        setRequiredOtpResult({
          ...requiredOtpResult,
          isLogined: true
        });

        history.push(pageURL.home);
      }
    }
    hideLoading();
  });

  const loginAs = useCallback(async (payload) => {
    showLoading();
    const [err, res] = await tryCatch(authService.loginAs(payload));

    if (err) {
      openToaster('top-right', {
        severity: 'error',
        summary: t('message.error'),
        detail: t(err?.response?.data?.errors[0]),
        life: 3000
      });
      hideLoading();
      return;
    }

    if (res) {
      const { accessToken, user: responseUser } = res;

      if (accessToken) {
        setToken(accessToken.token);
        setUser(responseUser);
        setRequiredOtpResult({
          ...requiredOtpResult,
          isLogined: true
        });

        history.push(pageURL.home);
      }
    }
    hideLoading();
  });

  const logout = useCallback(() => {
    return new Promise((resolve, reject) => {
      logoutUser()
        .then(() => {
          removeToken(LOCAL_KEYS.Token);
          removeUser(LOCAL_KEYS.User);
          history?.push(pageURL.login);
          resolve();
        })
        .catch((error) => {
          reject(new Error(`Logout failed. Error:${error}`));
        });
    });
  }, [history]);

  const verifyCode = async (payload) => {
    showLoading();

    const [err, res] = await tryCatch(verifyCodeRequest(payload));

    if (err) {
      openToaster('top-right', {
        severity: 'error',
        summary: t('message.error'),
        detail: t(err?.response?.data.errors[0]),
        life: 4000
      });
      hideLoading();
      return;
    }

    if (res && res.accessToken) {
      setRequiredOtpResult({
        ...requiredOtpResult,
        otpRequired: false,
        transactionGuid: null
      });

      setToken(res.accessToken.token);
      setUser(res.user);
      history.push(pageURL.home);

      hideLoading();
    }
  };
  const updateUserLanguage = async (payload) => {
    showLoading();
    const [err, res] = await tryCatch(userService.updateUserLanguage(payload));

    if (err) {
      openToaster('top-right', {
        severity: 'error',
        summary: t('message.error'),
        detail: t(err?.response?.data.errors[0]),
        life: 4000
      });
      hideLoading();
      return;
    }
    if (res) {
      setUser(res);
    }
    hideLoading();
  };

  const context = useMemo(
    () => ({
      user,
      token,
      login,
      loginAs,
      logout,
      verifyCode,
      requiredOtpResult,
      setRequiredOtpResult,
      updateUserLanguage
    }),
    [
      user,
      token,
      login,
      loginAs,
      logout,
      verifyCode,
      requiredOtpResult,
      setRequiredOtpResult,
      updateUserLanguage
    ]
  );

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}
