import { tryCatch } from '@thalesrc/js-utils';

import http from './http';
import { LANGUAGE_URL } from '../utils/url';

async function getAllLanguages() {
  const [error, result] = await tryCatch(http.get(`${LANGUAGE_URL}`));

  if (error) throw error;

  return result.data.data;
}

export const languageService = {
  getAllLanguages
};
