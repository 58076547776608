const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const LOGIN_AS_URL = `${BASE_URL}/auth/login-as`;
export const LOGOUT_URL = `${BASE_URL}/auth/logout`;
export const VERIFY_CODE_URL = `${BASE_URL}/auth/verify-code`;
export const RE_SEND_CODE_URL = `${BASE_URL}/auth/resend-code`;
export const GET_CUSTOMER_URL = `${BASE_URL}/customer`;
export const GENERATE_PASSWORD = `${BASE_URL}/auth/generate-password`;
export const FORGOT_PASSWORD = `${BASE_URL}/auth/remind-password`;
export const RESET_PASSWORD = `${BASE_URL}/auth/reset-password`;
export const CHANGE_PASSWORD = `${BASE_URL}/auth/change-password`;
export const SETTINGS = `${BASE_URL}/settings`;
export const USER_URL = `${BASE_URL}/user`;
export const LANGUAGE_URL = `${BASE_URL}/languages`;
export const MODULE_URL = `${BASE_URL}/module`;
export const PROFILE_URL = `${BASE_URL}/profile`;
export const SUPPLIER_URL = `${BASE_URL}/supplier`;
export const PRODUCT_BARCODE_URL = `${BASE_URL}/product-barcode`;
export const PRODUCT_BARCODE_FILE_URL = `${BASE_URL}/product-barcode-file`;
