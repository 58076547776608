import React, { useRef, useContext } from 'react';

import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

import { LanguageContext } from '../context/LanguageContext';

export default function LanguageSelector() {
  const { languages, changeLanguage, selectedLanguage } = useContext(LanguageContext);
  const languageMenu = useRef(null);

  const languageItems = languages.map((item) => ({
    label: item.name,
    template: (
      <button
        type="button"
        onClick={() => handleChangeLang(item.id)}
        className={classNames('w-full p-link flex align-items-center p-2', {
          'bg-gray-200': item.code === selectedLanguage
        })}>
        <span alt={item.name} className={`mr-2 flag flag-${item.code.toLocaleLowerCase()}`} />
        <div>{item.name}</div>
      </button>
    )
  }));

  const handleChangeLang = async (code) => {
    await changeLanguage(code);
  };

  return (
    <>
      <Button
        onClick={(event) => languageMenu.current.toggle(event)}
        className="border-round-xl"
        text
        aria-label="Language">
        <span className={`flag flag-${selectedLanguage}`} />
        <span className="ml-2 text-black-alpha-70">
          {languages
            .find((item) => item.code === selectedLanguage.toLocaleUpperCase())
            ?.code.toLocaleUpperCase()}
        </span>
      </Button>
      <Menu model={languageItems} popup ref={languageMenu} id="languageMenu" /> {}
    </>
  );
}
