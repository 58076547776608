import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

import { tryCatch } from '@thalesrc/js-utils';
import { useTranslation } from 'react-i18next';

import { ToasterContext } from './ToasterContext';
import { UserContext } from './UserContext';
import { LOCAL_KEYS } from '../enums/const';
import { languageService } from '../services/languages';

export const LanguageContext = createContext({
  languages: [],
  selectedLanguage: null,
  setLanguage: () => null,
  changeLanguage: () => null
});

export function LanguageContextProvider({ children }) {
  const { openToaster } = useContext(ToasterContext);
  const { i18n, t } = useTranslation();
  const { user, updateUserLanguage } = useContext(UserContext);
  const [selectedLanguage, setSelectedLanguage] = useState('tr');
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      const [err, res] = await tryCatch(languageService.getAllLanguages());
      if (err) {
        openToaster('top-right', {
          severity: 'error',
          summary: t('message.error'),
          detail: t(err?.response?.data?.errors[0]),
          life: 3000
        });
        return;
      }
      setLanguages(res);
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    const detectLanguage = () => {
      const browserLang = navigator.language.split('-')[0].toLowerCase(); // Tarayıcı dilini al
      const availableLanguages = languages?.map((lang) => lang.code.toLowerCase());

      const userLanguage = user?.languageCode?.toLowerCase();

      const fallbackLang = availableLanguages?.includes(browserLang) ? browserLang : 'tr';
      const storedLang = localStorage.getItem(LOCAL_KEYS.Lang);
      const selectedLang = userLanguage || storedLang || fallbackLang;
      setLanguage(selectedLang);
    };

    if (languages) {
      detectLanguage();
    }
  }, [user]);

  const changeLanguage = async (id) => {
    if (user) {
      const payload = { LanguageId: id };
      await updateUserLanguage(payload);
    } else {
      const selectedLanguageCode = languages.find((lang) => lang.id === id);
      if (selectedLanguageCode) {
        const code = selectedLanguageCode.code.toLowerCase();
        localStorage.setItem(LOCAL_KEYS.Lang, code);
      }
    }
    window.location.reload();
  };

  const setLanguage = (code) => {
    localStorage.setItem(LOCAL_KEYS.Lang, code);
    setSelectedLanguage(code);
    i18n.changeLanguage(code);
  };

  const context = useMemo(
    () => ({
      selectedLanguage,
      setLanguage,
      changeLanguage,
      languages
    }),
    [selectedLanguage, setLanguage, changeLanguage, languages]
  );

  return <LanguageContext.Provider value={context}>{children}</LanguageContext.Provider>;
}
